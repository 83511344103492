import { EventAggregator } from 'aurelia-event-aggregator';
import { autoinject } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { Models } from 'models/core';
import { ErrorService } from 'services/error-service';
import { SegmentService } from 'services/segment-service';
import { ToastService } from 'services/toast-service';

@autoinject
export class SegmentNew {
  private segment: Models.Segment = new Models.Segment();

  private constructor(
    private errorService: ErrorService,
    private eventAggregator: EventAggregator,
    private router: Router,
    private toastService: ToastService,
    private segmentService: SegmentService
  ) {}

  private createSegment() {
    this.segmentService
      .post(this.segment)
      .then((res) => {
        this.eventAggregator.publish('segmentListReset', 1);
        this.toastService.showSuccess('segment.created');
        this.router.navigateToRoute('segment-detail', { Id: res.Id });
      })
      .catch((err) => this.errorService.handleError(err));
  }
}
