import { ServiceCheckpointTypeService } from './../../services/service-checkpoint-type-service';
import { ErrorService } from "services/error-service";
import { Router } from "aurelia-router";
import { Models } from "models/core";
import { ServiceStatusService } from "services/service-status-service";
import { autoinject } from "aurelia-framework";
import { ToastService } from "services/toast-service";
import { EventAggregator } from "aurelia-event-aggregator";

@autoinject
export class ServiceCheckpointTypeNew {
  private type: Models.ServiceCheckpointType = new Models.ServiceCheckpointType();

  constructor(
    private serviceCheckpointTypeService: ServiceCheckpointTypeService, 
    private router: Router,
    private toastService: ToastService,
    private errorService: ErrorService,
    private eventAggregator: EventAggregator
  ) {}

  createServiceCheckpointType() {
    this.serviceCheckpointTypeService
      .post(this.type)
      .then(res => {
        this.eventAggregator.publish("serviceCheckpointTypeListReset", 1);
        this.toastService.showSuccess("servicecheckpointtype.created");
        this.router.navigateToRoute("service-checkpoint-type-detail", { Id: res.Id });
      })
      .catch(err => this.errorService.handleError(err));
  }
}
