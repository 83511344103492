import { EventAggregator } from 'aurelia-event-aggregator';
import { autoinject } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { Models } from 'models/core';
import { DeleteDialogService } from 'services/delete-dialog-service';
import { ErrorService } from 'services/error-service';
import { SegmentService } from 'services/segment-service';
import { ToastService } from 'services/toast-service';

@autoinject
export class SegmentDetail {
  private segment: Models.Segment;

  private constructor(
    private errorService: ErrorService,
    private eventAggregator: EventAggregator,
    private router: Router,
    private toastService: ToastService,
    private segmentService: SegmentService,
    private deleteDialogService: DeleteDialogService
  ) {}

  private activate(params) {
    this.segmentService
      .get(params.Id)
      .then((res) => {
        this.segment = res;
      })
      .catch((err) => this.errorService.handleError(err));
  }

  private updateSegment() {
    if (this.segment.LeaderId == '') {
      this.segment.LeaderId = null;
    }
    this.segmentService
      .put(this.segment, this.segment.Id)
      .then((res) => {
        this.eventAggregator.publish('segmentListReset', 1);
        this.toastService.showSuccess('segment.updated');
        this.router.navigateToRoute('segment-list');
      })
      .catch((err) => this.errorService.handleError(err));
  }

  private deleteSegment() {
    this.deleteDialogService.confirmBeforeDelete(
      () => {
        this.segmentService
          .delete(this.segment.Id)
          .then((res) => {
            this.eventAggregator.publish('segmentListReset', 1);
            this.toastService.showSuccess('segment.deleted');
            this.router.navigateToRoute('segment-list');
          })
          .catch((err) => this.errorService.handleError(err));
        }
      );
  }

  private gotoParentView() {
    this.eventAggregator.publish('segmentListReset', 1);
    this.router.navigateToRoute('segment-list');
  }
}
