import { ServiceCheckpointGroupService } from './../../services/service-checkpoint-group-service';
import { Models } from "models/core";
import { autoinject, bindable, bindingMode, LogManager } from "aurelia-framework";
import { Logger } from 'aurelia-logging';

const logger: Logger = LogManager.getLogger("service-checkpoint-type-form");

@autoinject
export class ServiceCheckpointTypeForm {
  @bindable({ defaultBindingMode: bindingMode.twoWay } ) type: any;

  constructor(
    private serviceCheckpointGroupService: ServiceCheckpointGroupService    
  ) {
    logger.debug('form constructor');
  }  

  bind() {
    logger.debug('bind', this.type);
  }
}
