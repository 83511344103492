import { DeleteDialogService } from 'services/delete-dialog-service';
import { ServiceCheckpointTypeService } from './../../services/service-checkpoint-type-service';
import { ErrorService } from "services/error-service";
import { Router } from "aurelia-router";
import { autoinject } from "aurelia-framework";
import { ServiceStatusService } from "services/service-status-service";
import { Models } from "models/core";
import { ToastService } from "services/toast-service";
import { EventAggregator } from "aurelia-event-aggregator"; 

@autoinject
export class ServiceCheckpointTypeDetail {
  private type: Models.ServiceCheckpointType;

  constructor(
    private serviceCheckpointTypeService: ServiceCheckpointTypeService,
    private errorService: ErrorService,
    private router: Router,
    private toastService: ToastService,
    private deleteDialogService: DeleteDialogService,
    private eventAggregator: EventAggregator
  ) {}

  activate(params) {
    this.serviceCheckpointTypeService
      .get(params.Id)
      .then(type => {
        this.type = type;
      })
      .catch(err => this.errorService.handleError(err));
  }

  updateServiceCheckpointType() {
    delete this.type.ServiceCheckpointGroup;

    this.serviceCheckpointTypeService
      .put(this.type, this.type.Id)
      .then(res => {        
        this.toastService.showSuccess("servicecheckpointtype.updated");
        this.gotoParentView();
      })
      .catch(err => this.errorService.handleError(err));
  }

  deleteCheckpointType() {
    this.deleteDialogService.confirmBeforeDelete(() => {
      this.serviceCheckpointTypeService
        .delete(this.type.Id)
        .then(res => {        
          this.toastService.showSuccess("servicecheckpointtype.deleted");
          this.gotoParentView();
        })
        .catch(err => this.errorService.handleError(err));
    });
    
  }

  gotoParentView(){
    this.eventAggregator.publish("serviceCheckpointTypeListReset", 1);
    this.router.navigateToRoute("service-checkpoint-type-list");   
  }
}
