import { autoinject, bindable } from 'aurelia-framework';
import { Models } from 'models/core';
import { UserService } from 'services/user-service';

@autoinject
export class SegmentForm {
  @bindable private segment: Models.Segment;

  private constructor(
    private userService: UserService
  ) {}
}
