import { DialogCloseResult, DialogController, DialogService } from 'aurelia-dialog';
import { EventAggregator } from 'aurelia-event-aggregator';
import { autoinject, bindable } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';
import { ErrorService } from 'services/error-service';
import { ServiceService } from 'services/service-service';
import { Utility } from 'utility';
import { Prompt } from '../../elements/prompt';
import { ServiceDetailOrderdetails } from '../service/service-detail-orderdetails';
import { AuditLogService } from './../../services/audit-log-service';

@autoinject
export class ServiceDialog {

    private serviceId;
    private netId;
    private canDeactivateExternal;
    private dialog: boolean = true;

    @bindable private service;
    @bindable private originalObject;

    private serviceDetailMain;
    private ready: boolean = false;

    constructor(
      private dialogController: DialogController,
      private auditLogService: AuditLogService,
      private errorService: ErrorService,
      private dialogService: DialogService,
      private serviceDetailOrderdetails: ServiceDetailOrderdetails,
      private utility: Utility,
      private serviceService: ServiceService,
      private eventAggregator: EventAggregator
    ) {}

    private activate(params) {
      this.serviceId = params.Id;
      this.netId = params.NetId;

      this.eventAggregator.subscribe('closeServiceDetailsDialog', (data) => {
        this.dialogController.cancel('force');
      });

      this.eventAggregator.subscribe('serviceMainHeaderReady', (data) => {
        this.ready = true;
      });
    }

    private canDeactivate(dialogResult: DialogCloseResult) {
      if (dialogResult.output === 'force') {
        return true;
      } else {
        return this.serviceDetailMain.viewModel.canDeactivate();
      }
    }
}
